import { createI18n } from 'vue-i18n';

const messages = {
  "EN": {
    loading: "Loading...",
    select: "Select",
    success: "Success!",
    error: "Error!",
    content_copy: "Content copied",
    error_content_copy: "Error copying content",
    error_img_200: "Image size must be less than 400x400 pixels. This image has {width}{'x'}{height}",
    error_copy_enterprise_data: "Error copying enterprise data",
    copy_enterprise_data: "Click here to repeat enterprise",
    error_api_call: "Error calling API",
    success_api_call: "API call successful",
    subheader: {
      store: "Store",
      company: "Company",
      search: "Search companies...",
      search_company_error: "Error searching companies",
      search_store_error: "Error searching stores",
      no_records_found: "No records found",
    },
    header: {
      leave: "Leave",
      notification: "Notification",
      language: "Language",
      profile: "Profile",
      invite_user: "Invite User",
      change_password: "Change Password",
    },
    sidebar: {
      open_menu: "Open menu",
      close_menu: "Close menu",
      item_company: "Company",
      item_products: "Products",
      item_users: "Users",
      item_orders: "Orders",
      item_config: "Configuration",
      item_app: "Applications",
      item_help: "Help",
    },
    breadcrumb: {
      home: "Home",
      company: "Company",
      products: "Products",
      users: "Users",
      orders: "Orders",
      monitoring: "Monitoring",
      app: "Applications",
      help: "Help",
      company_tabs: {
        stores: "Stores",
        branches: "Branches"
      },
      users_profiles: "Profiles",
      register_product: "Registration",
      edit_product: "Edit",
      product_variations: "Registration",
      edit_product_variations: "Edit",
      product_attributes: "Registration",
      edit_product_attributes: "Edit",
      product_precification: "Registration",
      edit_product_precification: "Edit",
      product_stock: "Registration",
      edit_product_stock: "Edit",
      product_restrictions: "Registration",
      edit_product_restrictions: "Edit"
    },
    company: {
      tabs: {
        company: "COMPANY",
        stores: "STORES",
        branches: "BRANCHES"
      },
      forms: {
        company: {
          corporate_name: "Corporate Name",
          corporate_name_required: "Corporate Name is required",
          trade_name: "Trade Name",
          trade_name_required: "Trade Name is required",
          sistem_name: "System Name",
          sistem_name_required: "System Name is required",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ is required",
          ie: "State Registration",
          postal_code: "Postal Code",
          postal_code_required: "Postal Code is required",
          address: "Address",
          address_required: "Address is required",
          number: "Number",
          number_required: "Number is required",
          neighborhood: "Neighborhood",
          neighborhood_required: "Neighborhood is required",
          address_complement: "Complement",
          city: "City",
          city_required: "City is required",
          state: "State",
          state_required: "State is required",
          phone: "Phone",
          country: "Country",
          country_required: "Country is required",
          currency: "Currency",
          select_image: "Select an image here!",
        },
        responsible: {
          name: "Name",
          department: "Department",
          email: "E-mail",
          phone: "Phone",
          cellphone: "Mobile Phone",
          name_required: "Name is required",
          email_required: "E-mail is required",
          email_invalid: "Invalid e-mail",
          department_required: "Department is required",
        }
      },
      tables: {
        managers: {
          name: "Name",
          department: "Department",
          email: "E-mail",
          phone: "Phone",
          mobile_phone: "Mobile Phone",
          id: "ID",
        },
        stores: {
          store_id: "ID",
          store_name: "Name",
          city: "City",
          state: "State",
          responsible: "Responsible",
          email: "E-mail",
          cellphone: "Cellphone",
          phone: "Phone",
          status: "Status",
          actions: "Actions",
        }
      },
      modals: {
        delete_responsible: {
          header: "Important!",
          message: "Do you really want to delete this responsible? By clicking the Yes button, the changes will be immediately made."
        },
        inactivate_company: {
          header: "Important!",
          message: "Do you really want to inactivate this company? By clicking the Yes button, the changes will be immediately made."
        },
        new_responsible: {
          header: "New Responsible",
          button_cancel: "Cancel",
          button_save: "Save",
          button_back: "Return"
        },
        edit_reponsible: {
          header: "Edit Responsible",
          button_cancel: "Cancel",
          button_save: "Save",
        }
      },
      button_yes: "Yes",
      button_no: "No",
      active: "active",
      inactive: "inactive",
      button_edit: "Edit",
      button_new_responsible: "New Responsible",
      general_information: "GENERAL INFORMATION",
      managers: "Managers",
      no_stores: "No stores registered",
      no_stores_description: "Register a new store by clicking the button above",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Store Name",
          responsible: "Responsible",
          email: "E-mail",
          phone: "Phone",
          city: "City",
          state: "State",
          cellphone: "Cellphone",
        },
      },
      modals: {
        new_store: {
          header: "New Store",
          button_cancel: "Cancel",
          button_save: "Save",
          store_name_required: "Store name is required",
          city_required: "City is required",
          state_required: "State is required",
          email_invalid: "Invalid e-mail",
        },
        edit_store: {
          header: "Edit Store",
        }
      },
      store_list: "STORE LIST",
      button_filter: "Filters",
      button_add_store: "Add Store",
      fetch_store_error: "Error searching stores",
      error_no_enterprise: "No enterprise selected",
    },
    company_branches: {
      tables: {
        branches: {
          id: "ID",
          name: "Name",
          tax_id: "CNPJ",
          type: "Branch Type",
          city: "City",
          state: "State",
          responsible: "Responsible",
          store: "Store",
          status: "Status",
          actions: "Actions",
          branch_types: {
            physical_store: "Physical Store",
            pickup_point: "Pickup Point",
            distribution_center: "Distribution Center",
            head_office: "Head Office",
            fulfillment_center: "Fulfillment Center",
          }
        }
      },
      modals: {
        new_branch: {
          header: "New Branch",
          button_cancel: "Cancel",
          button_save: "Save",
        },
        edit_branch: {
          header: "Edit Branch",
          button_cancel: "Cancel",
          button_save: "Save",
        },
        filter: {
          show: '',
          form: {
            active: 'Active',
            inactive: 'Inactive',
            name: 'Name',
            tax_identifier: 'Tax ID',
            city: 'City',
            responsible: 'Responsible',
            uf: 'State',
            store: 'Store'
          }
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ is required",
          branch_name: "Branch Name",
          branch_name_required: "Branch Name is required",
          postal_code: "Postal Code",
          postal_code_required: "Postal Code is required",
          address: "Address",
          address_required: "Address is required",
          address_complement: "Complement",
          city: "City",
          city_required: "City is required",
          state: "State",
          state_required: "State is required",
          state_placeholder: "Select a state",
          state_registration: "State Registration",
          responsable: "Responsable",
          store: "Stores",
          store_required: "Store is required",
          store_placeholder: "Select a store",
          stores_no_result: "No stores found",
          branch_type: "Branch Type",
          number: "Number",
          number_required: "Number is required",
          branch_type_placeholder: "Select a branch type",
          branch_type_required: "Branch Type is required",
          branch_types: {
            physical_store: "Physical Store",
            pickup_point: "Pickup Point",
            distribution_center: "Distribution Center",
            head_office: "Head Office",
            fulfillment_center: "Fulfillment Center",
          }
        }
      },
      error_search_branches: "Error searching branches",
      empty_branches: "No branches found",
      empty_branches_description: "Register a new branch by clicking the button above",
      filial_list: "BRANCH LIST",
      button_add_branch: "Add Branch",
      filters: "Filters"
    },
    users: {
      tables: {
        users: {
          id: "ID",
          name: "Name",
          email: "E-mail",
          role: "Role",
          department: "Department",
          status: "Status",
          actions: "Actions",
        }
      },
      modals: {
        newUser: {
          newUser: "New User",
          photo_size_recommend: "We recommend an image of 300x300 pixels",
          alt_img_preview: "Image preview",
        },
        editUser: {
          editUser: "Edit User",
          photo_size_recommend: "We recommend an image of 300x300 pixels",
          alt_img_preview: "Image preview",
        }
      },
      forms: {
        new_user: {
          name: "Name",
          email: "E-mail",
          profile: "Access Profile",
          stores: "Stores",
          realesed_stores: "Released Stores",
          department: "Department",
          name_required: "Name is required",
          email_required: "E-mail is required",
          email_invalid: "Invalid e-mail",
          stores_required: "Stores is required",
          department_required: "Department is required",
          profile_required: "Profile is required",
        }
      },
      no_users: "No users found",
      no_users_description: "Register a new user by clicking the button above",
      fetch_users_error: "Error fetching users",
      users_list: "USERS LIST",
      search: "Search users...",
      button_access_profiles: "Access Profiles",
      button_add_user: "Add User",
    },
    users_profiles: {
      profile_management: "PROFILE MANAGEMENT",
      button_duplicate_profile: "Duplicate Profile",
      button_add_profile: "Add Profile",
      available_options: "Available Options",
      options_released: "Options Released",
      profile_list: "PROFILE LIST",
      list_of_functionalities: "LIST OF FUNCTIONALITIES",
      clear_selection: "Clear selection"
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Password",
        no_account: "Don't have an account yet?",
        no_account_click_here: "Click here",
        forgot_my_password: "Forgot my password",
        log_in: "LOG IN"
      },
      forms: {
        login: {
          email_required: 'E-mail is required',
          password_required: 'Password is required',
          email_invalid: 'E-mail is invalid'
        },
        reset_password: {
          email_required: 'E-mail is required',
          email_invalid: 'E-mail is invalid'
        }
      },
      reset_password: {
        title: 'Reset Password',
        confirm: 'RESET',
        return: ' to back to login screen',
        click_here: 'Click here'
      },
      register: {
        email: "Email",
        password: "Password",
        sign_up: "SIGN UP",
        department: "Department",
        name: "Name",
        confirm_password: "Confirm Password"
      }
    },
    errors: {
      unexpected_error: {
        title: "UNEXPECTED ERROR!",
        description: "An unexpected error occurred in our system, please try again later."
      }
    },
    products: {
      tabs: {
        general_data: "GENERAL DATA",
        variations: "VARIATIONS",
        attributes: "ATTRIBUTES",
        precification: "PRECIFICATION",
        stock: "STOCK",
        restrictions: "RESTRICTIONS",
      },
      products_list: {
        modals: {
          filter: {
            close_filter: "Close Filters",
            show: "Show:",
            active: "Active",
            inactive: "Inactive",
            form: {
              sku_group: "SKU Group",
              product_type: "Product Type",
              category: "Category",
              brand: "Brand",
              price: "Price",
              initial_amount: "Initial Value",
              final_amount: "Final Value",
              stock: "Stock",
              initial_quantity: "Initial Quantity",
              final_quantity: "Final Quantity",
            },
            apply_filters: "Apply Filters",
            clean_filters: "Clean Filters",
          },
          variations: {
            product_variations: "Product Variations",
            sku_group: "SKU Group",
            title: "Title",
            table: {
              ref_code: "Reference Code",
              ean: "Barcode (EAN)",
              primary_characteristic: "Primary Characteristic",
              secondary_characteristic: "Secondary Characteristic",
              active: "Active",
              inactive: "Inactive",
            },
            close: "Close",
            access_product: "Access Product",
          }
        },
        tables: {
          products: {
            sku_group: "SKU Group",
            image: "Image",
            title: "Title",
            product_type: "Product Type",
            kit_variation: "Kit with Variation",
            variation: "With Variation",
            simple: "Simple",
            category: "Category",
            brand: "Brand",
            model: "Model",
            standard_price: "Standard Price",
            total_stock: "Total Stock",
          },
        },
        title: "PRODUCT LIST",
        search: "Search products...",
        filters: "Filters",
        publications: "Publications",
        add_product: "Add Product",
        no_products: "No products found",
        no_products_description: "Register a new product by clicking the button above",
      },
      details: {
        no_categories: "No categories registered",
        select_category: "Select a category",
        select_gender: "Select a gender",
        select_manufactorer: "Select a manufacturer",
        no_category: "No category found",
        product_details: "GENERAL PRODUCT INFORMATION",
        history: "History",
        import_product: "Import Product",
        select_image: "Add images in the Variations tab",
        sku_group: "SKU Group",
        product_status: "Product Status",
        used_characteres: "Used characters",
        forms: {
          product: {
            title: "Title",
            title_required: "Title is required",
            title_helper: "(Title send to marketplace)",
            short_title: "Short Title",
            category: "Category",
            brand: "Brand",
            model: "Model",
            nbm: "NBM/NCM",
            gender: "Gender",
            volumes: "Volumes",
            manufacturer: "Manufacturer",
            warranty_period: "Warranty Period",
            months: "(Months)",
            package_information: "PACKAGE INFORMATION",
            weight: "Weight",
            height: "Height",
            width: "Width",
            depth: "Depth",
            description: "Description",
            cubage: "Cubage",
          }
        },
        modals: {
          import_product: {
            import_product: "Import Product",
            import_button: "Import",
          },
          history: {
            product_history: "Product History",
            close: "Close",
          },
          commentaries: {
            product_commentaries: "Product Commentaries",
            digit_here: "Digit here",
            send: "Send",
          }
        },
        editor: {
          bold: "Bold",
          italic: "Italic",
          underline: "Underline",
          help: "The maximum character limits allowed by each marketplace will be published. Descriptions exceeding these limits will be truncated!"
        }
      },
      variations: {
        no_variations: "No variations found",
        no_variations_descriptions: "Register a new variation by clicking the button above",
        product_variations: "PRODUCT VARIATIONS",
        characteristics_types: "Characteristics Types",
        add_variation: "Add Variation",
        main_variation: "Main Variation",
        variation: "Variation",
        main_image: "Main Image",
        photos_inserted: "Photos inserted",
        upload_photos: "Upload photos",
        primary_characteristic: "Primary Characteristic",
        secondary_characteristic: "Secondary Characteristic",
        select: "Select...",
        ref_code: "Reference Code",
        bar_code: "Barcode",
        modals: {
          characteristics: {
            characteristics_type: "CHARACTERISTICS TYPE",
            add_characteristics_type: "Add Characteristics Type",
            name: "Name",
            value: "Value",
          }
        }
      },
      precification: {
        sku_group: "SKU Group",
        price_from: "Price From",
        discount: "Discount",
        standard_price: "Standard Price",
        sku: "SKU",
        reference_code: "Reference Code",
        primary_feature: "Primary Feature",
        secondary_feature: "Secondary Feature",
        voltage: "Voltage",
        barcode: "Barcode",
        standard_price_per_sku: "Standard Price per SKU",
        prices_practiced_in_marketplaces_title: "Prices practiced in marketplaces",
        prices_practiced_in_marketplaces: {
          channel: "Channel",
          price: "Price",
          note: "Note"
        }
      },
      attributes: {
        attributes_by_marketplace: "Attributes by Marketplace",
        category: "Category",
        attribute: "Attribute",
        value: "Value",
        attribute_name_already_exists: "The attribute name is already being used.",
        product_attributes: "Product Attributes",
        add_attribute: "Add Attribute",
        search: "Search"
      },
      stock: {
        available_stock_by_sku_and_branch: "AVAILABLE STOCK BY SKU AND BRANCH",
        sku_group: "SKU Group",
        sku: "SKU",
        reference_code: "Reference Code",
        primary_characteristic: "Primary Characteristic",
        secondary_characteristic: "Secondary Characteristic",
        gtin: "Barcode",
        available_total_sku_group: 'Total Available Stock SKU Group',
        minimum_stock_sku_group: 'Minimum Stock SKU Group',
        available_total: "Available Total",
        minimum_stock: "Minimum Stock",
        restrictions_by_marketplace: "Restrictions by Marketplace",
        branch: {
          available_stock: "Available Stock",
          stock_type: "Stock Type",
          safety_stock: "Safety Stock",
          additional_lead_time: "Additional Lead Time",
          select: "Select..."
        },
        modals: {
          available_total_sku_group_info: "Total quantity of items available in stock for the SKU group.",
          help_stock: {
            help_stock: "Help",
            subtitle: "TYPES OF STOCK",
            stock_automatic_highlight: "Automatic – API Interaction:",
            stock_automatic_description: "In this option, the “Available Stock” will be updated via API.",
            stock_automatic_observation: "The crossdocking deadline “Additional Time” does not apply to this option.",
            observation: "Observation:",
            observations: "Observations:",
            stock_fix_highlight: "Fixed Stock:",
            stock_fix_description: "In this option, the “Available Stock” will be manually entered and will not change if sales occur.",
            stock_fix_observation: "The value entered in the “Additional Time” field for delivery will be added to the transportation time.",
            reducing_stock: "Reducing Stock Item:",
            reducing_stock_description: "In this option, the “Available Stock” will be manually entered and will decrease with sales.",
            observation_1: "If there is a pending order cancellation, the stock will not increase automatically.",
            observation_2: "The value entered in the “Additional Deadline” field for delivery will be added to the shipping time."
          }
        }
      },
      restrictions: {
        ref_code: "Reference Code",
        primary_characteristic: "Primary Feature",
        secondary_characteristic: "Secondary Characteristic",
        barcode: "Barcode",
        marketplace_restrictions: "Sales Restrictions by Marketplace"
      }
    }
  },
  "pt_BR": {
    loading: "Carregando...",
    select: "Selecione",
    success: "Sucesso!",
    error: "Erro!",
    content_copy: "Conteúdo copiado",
    error_content_copy: "Erro ao copiar conteúdo",
    error_img_200: "Imagem deve ter menos de 200x200 pixels",
    error_copy_enterprise_data: "Erro ao copiar dados da empresa",
    copy_enterprise_data: "Clique aqui para repetir os dados da empresa",
    error_api_call: "Erro ao chamar API",
    success_api_call: "Chamada API bem-sucedida",
    subheader: {
      store: "Loja",
      company: "Empresa",
      search: "Pesquisar empresas...",
      search_company_error: "Erro ao buscar empresas",
      search_store_error: "Erro ao buscar lojas",
      no_records_found: "Nenhum registro encontrado",
    },
    header: {
      leave: "Sair",
      notification: "Notificação",
      language: "Idioma",
      profile: "Perfil",
      invite_user: "Convidar Usuário",
      change_password: "Alterar Senha",
    },
    sidebar: {
      open_menu: "Abrir menu",
      close_menu: "Fechar menu",
      item_company: "Empresa",
      item_products: "Produtos",
      item_users: "Usuários",
      item_orders: "Pedidos",
      item_config: "Configurações",
      item_app: "Aplicativos",
      item_help: "Ajuda",
    },
    breadcrumb: {
      home: "Home",
      company: "Empresa",
      products: "Produtos",
      users: "Usuários",
      orders: "Pedidos",
      monitoring: "Monitoramento",
      app: "Aplicativos",
      help: "Ajuda",
      company_tabs: {
        stores: "Lojas",
        branches: "Filiais"
      },
      users_profiles: "Perfis",
      register_product: "Cadastro",
      edit_product: "Editar",
      product_variations: "Cadastro",
      edit_product_variations: "Editar",
      product_attributes: "Cadastro",
      edit_product_attributes: "Editar",
      product_precification: "Cadastro",
      edit_product_precification: "Editar",
      product_stock: "Cadastro",
      edit_product_stock: "Editar",
      product_restrictions: "Cadastro",
      edit_product_restrictions: "Editar"
    },
    company: {
      tabs: {
        company: "EMPRESA",
        stores: "LOJAS",
        branches: "FILIAIS",
      },
      forms: {
        company: {
          corporate_name: "Razão Social",
          corporate_name_required: "Razão Social é obrigatório",
          trade_name: "Nome Fantasia",
          trade_name_required: "Nome Fantasia é obrigatório",
          sistem_name: "Nome no Sistema",
          sistem_name_required: "Nome no Sistema é obrigatório",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ é obrigatório",
          ie: "Inscrição Estadual",
          postal_code: "CEP",
          postal_code_required: "CEP é obrigatório",
          address: "Endereço",
          address_required: "Endereço é obrigatório",
          number: "Número",
          number_required: "Número é obrigatório",
          neighborhood: "Bairro",
          neighborhood_required: "Bairro é obrigatório",
          address_complement: "Complemento",
          city: "Cidade",
          city_required: "Cidade é obrigatório",
          state: "Estado",
          state_required: "Estado é obrigatório",
          phone: "Telefone",
          country: "País",
          country_required: "País é obrigatório",
          currency: "Moeda",
          select_image: "Selecione uma imagem aqui!",
        },
        responsible: {
          name: "Nome",
          department: "Departamento",
          email: "E-mail",
          phone: "Telefone",
          cellphone: "Celular",
          name_required: "Nome é obrigatório",
          email_required: "E-mail é obrigatório",
          department_required: "Departamento é obrigatório",
        }
      },
      tables: {
        managers: {
          name: "Nome",
          department: "Departamento",
          email: "E-mail",
          phone: "Telefone",
          mobile_phone: "Celular",
          id: "Código",
        },
        stores: {
          store_id: "Cód.",
          store_name: "Nome",
          city: "Cidade",
          state: "Estado",
          responsible: "Responsável",
          email: "E-mail",
          cellphone: "Celular",
          phone: "Telefone",
          status: "Status",
          actions: "Ações",
        }
      },
      modals: {
        delete_responsible: {
          header: "Importante!",
          message: "Deseja realmente excluir esse responsável? Ao clicar no botão Sim, as alterações serão imediatamente realizadas."
        },
        inactivate_company: {
          header: "Importante!",
          message: "Deseja realmente inativar essa empresa? Ao clicar no botão Sim, as alterações serão imediatamente realizadas."
        },
        new_responsible: {
          header: "Novo Responsável",
          button_cancel: "Cancelar",
          button_save: "Salvar",
          button_back: "Voltar"
        },
        edit_reponsible: {
          header: "Editar Responsável",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        }
      },
      button_yes: "Sim",
      button_no: "Não",
      active: "ativo",
      inactive: "inativo",
      button_edit: "Editar",
      button_new_responsible: "Novo Responsável",
      general_information: "DADOS GERAIS DA EMPRESA",
      managers: "Responsáveis",
      no_stores: "Nenhuma loja cadastrada",
      no_stores_description: "Cadastre uma nova loja clicando no botão acima",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Nome da Loja",
          responsible: "Responsável",
          email: "E-mail",
          phone: "Telefone",
          city: "Cidade",
          state: "Estado",
          cellphone: "Celular",
        }
      },
      modals: {
        new_store: {
          header: "Nova Loja",
          button_cancel: "Cancelar",
          button_save: "Salvar",
          store_name_required: "Nome da loja é obrigatório",
          city_required: "Cidade é obrigatório",
          state_required: "Estado é obrigatório",
          email_invalid: "E-mail inválido",
        },
        edit_store: {
          header: "Editar Loja",
        }
      },
      store_list: "LISTAGEM DE LOJAS",
      button_filter: "Filtros",
      button_add_store: "Adicionar Loja",
      fetch_store_error: "Erro ao buscar lojas",
      error_no_enterprise: "Nenhuma empresa selecionada",
    },
    company_branches: {
      tables: {
        branches: {
          id: "Código",
          name: "Nome",
          tax_id: "CNPJ",
          type: "Tipo de filial",
          city: "Cidade",
          state: "UF",
          responsible: "Responsável",
          store: "Loja",
          status: "Status",
          actions: "Ações",
          branch_types: {
            physical_store: "Loja Física",
            pickup_point: "Ponto de Retirada",
            distribution_center: "Centro de Distribuição",
            head_office: "Matriz",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      modals: {
        new_branch: {
          header: "Nova Filial",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        },
        edit_branch: {
          header: "Editar Filial",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        },
        filter: {
          show: '',
          form: {
            active: 'Ativo',
            inactive: 'Inativo',
            name: 'Nome',
            tax_identifier: 'CNPJ',
            city: 'Cidade',
            responsible: 'Responsável',
            uf: 'UF',
            store: 'Loja'
          }
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ é obrigatório",
          branch_name: "Nome da Filial",
          branch_name_required: "Nome da Filial é obrigatório",
          postal_code: "CEP",
          postal_code_required: "CEP é obrigatório",
          address: "Endereço",
          address_required: "Endereço é obrigatório",
          address_complement: "Complemento",
          city: "Cidade",
          city_required: "Cidade é obrigatório",
          state: "Estado",
          state_required: "Estado é obrigatório",
          state_placeholder: "Selecione um estado",
          state_registration: "Inscrição Estadual",
          responsable: "Responsável",
          store: "Lojas",
          store_required: "Loja é obrigatório",
          store_placeholder: "Selecione uma loja",
          stores_no_result: "Nenhuma loja encontrada",
          branch_type: "Tipo de Filial",
          number: "Número",
          number_required: "Número é obrigatório",
          branch_type_placeholder: "Selecione um tipo de filial",
          branch_type_required: "Tipo de Filial é obrigatório",
          branch_types: {
            physical_store: "Loja Física",
            pickup_point: "Ponto de Retirada",
            distribution_center: "Centro de Distribuição",
            head_office: "Matriz",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      error_search_branches: "Erro ao buscar filiais",
      empty_branches: "Nenhuma filial encontrada",
      empty_branches_description: "Cadastre uma nova filial clicando no botão acima",
      filial_list: "LISTAGEM DE FILIAIS",
      button_add_branch: "Adicionar Filial",
      filters: "Filtros"
    },
    users: {
      tables: {
        users: {
          id: "ID",
          name: "Nome",
          email: "E-mail",
          role: "Perfil",
          department: "Departamento",
          status: "Status",
          actions: "Ações",
        }
      },
      modals: {
        newUser: {
          newUser: "Novo Usuário",
          photo_size_recommend: "Recomendamos uma imagem de 300x300 pixels",
          alt_img_preview: "Pré-visualização da imagem",
        },
        editUser: {
          editUser: "Editar Usuário",
          photo_size_recommend: "Recomendamos uma imagem de 300x300 pixels",
          alt_img_preview: "Pré-visualização da imagem",
        }
      },
      forms: {
        new_user: {
          name: "Nome",
          email: "E-mail",
          profile: "Perfil de Acesso",
          stores: "Lojas",
          realesed_stores: "Lojas Liberadas",
          department: "Departamento",
          name_required: "Nome é obrigatório",
          email_required: "E-mail é obrigatório",
          email_invalid: "E-mail inválido",
          department_required: "Departamento é obrigatório",
          profile_required: "Perfil é obrigatório",
        }
      },
      no_users: "Nenhum usuário encontrado",
      no_users_description: "Cadastre um novo usuário clicando no botão acima",
      fetch_users_error: "Erro ao buscar usuários",
      users_list: "LISTAGEM DE USUÁRIOS",
      search: "Pesquisar usuários...",
      button_access_profiles: "Perfis de Acesso",
      button_add_user: "Adicionar Usuário",
    },
    users_profiles: {
      profile_management: "GESTÃO DE PERFIS",
      button_duplicate_profile: "Duplicar Perfil",
      button_add_profile: "Adicionar Perfil",
      available_options: "Opções Disponíveis",
      options_released: "Opções Liberadas",
      clear_selection: "Limpar Marcações",
      profile_list: "LISTA DE PERFIS",
      list_of_functionalities: "LISTA DE FUNCIONALIDADES",
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Senha",
        no_account: "Ainda não possui uma conta?",
        no_account_click_here: "Clique aqui",
        forgot_my_password: "Esqueci minha senha",
        log_in: "INICIAR SESSÃO",
      },
      register: {
        email: "E-mail",
        password: "Senha",
        sign_up: "INICIAR SESSÃO",
        department: "Departamento",
        name: "Nome",
        confirm_password: 'Confirmar senha'
      },
      forms: {
        login: {
          email_required: 'E-mail é obrigatório',
          password_required: 'Senha é obrigatória',
          email_invalid: 'E-mail inválido'
        },
        reset_password: {
          email_required: 'E-mail é obrigatório',
          email_invalid: 'E-mail inválido'
        },
      },
      reset_password: {
        title: 'Reset Password',
        confirm: 'REDEFINIR',
        return: ' para voltar a tela de login.',
        click_here: 'Clique aqui'
      }
    },
    errors: {
      unexpected_error: {
        title: "ERRO INSPERADO!",
        description: "Ocorreu um erro inesperado em nosso sistema, por favor tente mais tarde."
      }
    },
    products: {
      tabs: {
        general_data: "DADOS GERAIS",
        variations: "VARIAÇÕES",
        attributes: "ATRIBUTOS",
        precification: "PRECIFICAÇÃO",
        stock: "ESTOQUE",
        restrictions: "RESTRIÇÕES",
      },
      products_list: {
        modals: {
          filter: {
            close_filter: "Recolher Filtros",
            show: "Exibir:",
            active: "Ativos",
            inactive: "Inativos",
            form: {
              sku_group: "SKU Grupo",
              product_type: "Tipo de Produto",
              category: "Categoria",
              brand: "Marca",
              price: "Preço",
              initial_amount: "Valor Inicial",
              final_amount: "Valor Final",
              stock: "Estoque",
              initial_quantity: "Quantidade Inicial",
              final_quantity: "Quantidade Final",
            },
            apply_filters: "Aplicar Filtros",
            clean_filters: "Limpar Filtros",
          },
          variations: {
            product_variations: "Variações do Produto",
            sku_group: "SKU Grupo",
            title: "Título",
            table: {
              ref_code: "Código de Referência",
              ean: "Código de Barras (EAN)",
              primary_characteristic: "Característica Primária",
              secondary_characteristic: "Característica Secundária",
              active: "Ativo",
              inactive: "Inativo",
            },
            close: "Fechar",
            access_product: "Acessar Produto",
          }
        },
        tables: {
          products: {
            sku_group: "SKU Grupo",
            image: "Imagem",
            title: "Título",
            product_type: "Tipo de Produto",
            kit_variation: "Kit com Variação",
            variation: "Com Variação",
            simple: "Simples",
            category: "Categoria",
            brand: "Marca",
            model: "Modelo",
            standard_price: "Preço Padrão",
            total_stock: "Estoque Total",
          }
        },
        title: "LISTAGEM DE PRODUTOS",
        search: "Pesquisar produtos...",
        filters: "Filtros",
        publications: "Publicações",
        add_product: "Adicionar Produto",
        no_products: "Nenhum produto encontrado",
        no_products_description: "Cadastre um novo produto clicando no botão acima",
      },
      details: {
        no_categories: "Nenhuma categoria cadastrada",
        select_category: "Selecione uma categoria",
        select_gender: "Selecione o gênero",
        select_manufactorer: "Selecione o fabricante",
        no_category: "Nenhuma categoria encontrada",
        product_details: "DADOS GERAIS DO PRODUTO",
        history: "Histórico",
        import_product: "Importar Produto",
        select_image: "Adicione imagens na aba Variações",
        sku_group: "SKU Grupo",
        product_status: "Status do Produto",
        used_characteres: "Caracteres utilizados",
        forms: {
          product: {
            title: "Título",
            title_required: "Título é obrigatório",
            title_helper: "(Título enviado aos Marketplaces)",
            short_title: "Título Curto",
            category: "Categoria",
            brand: "Marca",
            model: "Modelo",
            nbm: "NBM/NCM",
            gender: "Gênero",
            volumes: "Volumes",
            manufacturer: "Fabricação",
            warranty_period: "Período de Garantia",
            months: "(Meses)",
            package_information: "INFORMAÇÕES DE EMBALAGEM",
            weight: "Peso",
            height: "Altura",
            width: "Largura",
            depth: "Profundidade",
            cubage: "Cubagem",
            description: "Descrição",
          }
        },
        modals: {
          import_product: {
            import_product: "Importar Produto",
            import_button: "Importar",
          },
          history: {
            product_history: "Histórico do Produto",
            close: "Fechar",
          },
          commentaries: {
            product_commentaries:  "Comentários do Produto",
            digit_here: "Digite aqui",
            send: "Enviar",
          }
        },
        editor: {
          bold: "Negrito",
          italic: "Itálico",
          underline: "Sublinhado",
          help: "Serão publicados os valores máximos de caracteres permitidos por marketplace, sendo assim, descrições com limite ultrapassado serão cortadas!"
        }
      },
      variations: {
        no_variations: "Nenhuma variação encontrada",
        no_variations_descriptions: "Cadastre uma nova variação clicando no botão acima",
        product_variations: "VARIAÇÕES DO PRODUTO",
        characteristics_types: "Tipos de Características",
        add_variation: "Adicionar Variação",
        main_variation: "Variação Principal",
        variation: "Variação",
        main_image: "Imagem Principal",
        photos_inserted: "Fotos inseridas",
        upload_photos: "Carregar Fotos",
        primary_characteristic: "Característica Primária",
        secondary_characteristic: "Característica Secundária",
        select: "Selecione...",
        ref_code: "Código de Referência",
        bar_code: "Código de Barras",
        modals: {
          characteristics: {
            characteristics_type: "TIPO DE CARACTERÍSTICAS",
            add_characteristics_type: "Adicionar Tipo de Características",
            name: "Nome",
            value: "Valor",
          }
        }
      },
      precification: {
        sku_group: "SKU Grupo",
        price_from: "Preço De",
        discount: "Desconto",
        standard_price: "Preço Padrão",
        sku: "SKU",
        reference_code: "Código de Referência",
        primary_feature: "Característica Primária",
        secondary_feature: "Característica Secundária",
        voltage: "Voltagem",
        barcode: "Cód. de Barras",
        prices_practiced_in_marketplaces_title: 'Preços praticados nos marketplaces',
        prices_practiced_in_marketplaces: {
          channel: "Canal",
          price: "Preço",
          note: "Observação"
        }
      },
      attributes: {
        attributes_by_marketplace: "Atributos por Marketplace",
        category: "Categoria",
        attribute: "Atributo",
        value: "Valor",
        attribute_name_already_exists: "O nome do atributo já está sendo utilizado.",
        product_attributes: "Atributos do Produto",
        add_attribute: "Adicionar Atributo",
        search: "Pesquisar"
      },
      stock: {
        available_stock_by_sku_and_branch: "ESTOQUE DISPONÍVEL POR SKU E FILIAL",
        sku_group: "SKU Grupo",
        sku: "SKU",
        reference_code: "Código de Referência",
        primary_characteristic: "Característica Principal",
        secondary_characteristic: "Característica Secundária",
        gtin: "Código de Barras",
        available_total_sku_group: 'Estoque Total Disponível SKU Grupo',
        minimum_stock_sku_group: 'Estoque Mínimo SKU Grupo',
        available_total: "Total Disponível",
        minimum_stock: "Estoque Mínimo",
        restrictions_by_marketplace: "Restrições por Marketplace",
        branch: {
          available_stock: "Estoque Disponível",
          stock_type: "Tipo de Estoque",
          safety_stock: "Estoque de Segurança",
          additional_lead_time: "Tempo Adicional de Reposição",
          select: "Selecione..."
        },
        modals: {
          available_total_sku_group_info: "Quantidade total de itens disponíveis em estoque para o SKU grupo.",
          help_stock: {
            help_stock: "Ajuda",
            subtitle: "TIPOS DE ESTOQUE",
            stock_automatic_highlight: "Automático – Interação via API:",
            stock_automatic_description: "Nesta opção o “Estoque Disponível” será atualizado via API.",
            stock_automatic_observation: "O prazo de crossdocking “Tempo Adicional” não se aplica a esta opção.",
            observation: "Observação:",
            observations: "Observações:",
            stock_fix_highlight: "Estoque Fixo:",
            stock_fix_description: "Nesta opção o “Estoque Disponível” será informado manualmente e não mudará se houver vendas.",
            stock_fix_observation: "O valor informado no campo “Prazo Adicional” de entrega será somado ao prazo de transporte.",
            reducing_stock: "Baixando Item do Estoque:",
            reducing_stock_description: "Nesta opção o “Estoque Disponível” será informado manualmente e diminuirá se houver vendas.",
            observation_1: "Existindo um cancelamento de pedido pendente, o estoque não irá aumentar automaticamente.",
            observation_2: "O valor informado no campo “Prazo Adicional” de entrega será somado ao prazo de transporte."
          }
        }
      },
      restrictions: {
        ref_code: "Código de Referência",
        primary_characteristic: "Característica Prim.",
        secondary_characteristic: "Característica Sec.",
        barcode: "Cód. de Barras",
        marketplace_restrictions: "Restrições de Vendas por Marketplace"
      }
    }
  },
  "ES": {
    loading: "Cargando...",
    select: "Seleccionar",
    success: "¡Éxito!",
    error: "Error!",
    content_copy: "Contenido copiado",
    error_content_copy: "Error al copiar contenido",
    error_img_200: "La imagen debe tener menos de 200x200 píxeles",
    error_copy_enterprise_data: "Error al copiar los datos de la empresa",
    copy_enterprise_data: "Haga clic aquí para repetir la empresa",
    error_api_call: "Error al llamar a la API",
    success_api_call: "Llamada API exitosa",
    subheader: {
      store: "Tienda",
      company: "Empresa",
      search: "Buscar empresas...",
      search_company_error: "Error al buscar empresas",
      search_store_error: "Error al buscar tiendas",
      no_records_found: "No se encontraron registros",
    },
    header: {
      leave: "Salir",
      notification: "Notificación",
      language: "Idioma",
      profile: "Perfil",
      invite_user: "Invitar Usuario",
      change_password: "Cambiar Contraseña",
    },
    sidebar: {
      open_menu: "Abrir menú",
      close_menu: "Cerrar menú",
      item_company: "Compañía",
      item_products: "Productos",
      item_users: "Usuarios",
      item_orders: "Pedidos",
      item_config: "Configuración",
      item_app: "Aplicaciones",
      item_help: "Ayuda",
    },
    breadcrumb: {
      home: "Home",
      company: "Compañía",
      products: "Productos",
      users: "Usuarios",
      orders: "Pedidos",
      monitoring: "Monitoreo",
      app: "Aplicaciones",
      help: "Ayuda",
      company_tabs: {
        stores: "Tiendas",
        branches: "Sucursales"
      },
      users_profiles: "Perfiles",
      register_product: "Registro",
      edit_product: "Editar",
      product_variations: "Registro",
      edit_product_variations: "Editar",
      product_attributes: "Registro",
      edit_product_attributes: "Editar",
      product_precification: "Registro",
      edit_product_precification: "Editar",
      product_stock: "Registro",
      edit_product_stock: "Editar",
      product_restrictions: "Registro",
      edit_product_restrictions: "Editar",
    },
    company: {
      tabs: {
        company: "COMPAÑÍA",
        stores: "TIENDAS",
        branches: "SUCURSALES",
      },
      forms: {
        company: {
          corporate_name: "Razón Social",
          corporate_name_required: "Razón Social es obligatorio",
          trade_name: "Nombre Fantasía",
          trade_name_required: "Nombre Fantasía es obligatorio",
          sistem_name: "Nombre en el Sistema",
          sistem_name_required: "Nombre en el Sistema es obligatorio",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ es obligatorio",
          ie: "Inscripción Estatal",
          postal_code: "Código Postal",
          postal_code_required: "Código Postal es obligatorio",
          address: "Dirección",
          address_required: "Dirección es obligatorio",
          number: "Número",
          number_required: "Número es obligatorio",
          neighborhood: "Barrio",
          neighborhood_required: "Barrio es obligatorio",
          address_complement: "Complemento",
          city: "Ciudad",
          city_required: "Ciudad es obligatorio",
          state: "Estado",
          state_required: "Estado es obligatorio",
          phone: "Teléfono",
          country: "País",
          country_required: "País es obligatorio",
          currency: "Moneda",
          select_image: "Seleccione una imagen aquí!",
        },
        responsible: {
          name: "Nombre",
          department: "Departamento",
          email: "E-mail",
          phone: "Teléfono",
          cellphone: "Celular",
          name_required: "Nombre es obligatorio",
          email_required: "E-mail es obligatorio",
          email_invalid: "E-mail inválido",
          department_required: "Departamento es obligatorio",
        }
      },
      tables: {
        managers: {
          name: "Nombre",
          department: "Departamento",
          email: "E-mail",
          phone: "Teléfono",
          cellphone: "Celular",
          id: "Código"
        },
        stores: {
          store_id: "Cód.",
          store_name: "Nombre",
          city: "Ciudad",
          state: "Estado",
          responsible: "Responsable",
          email: "E-mail",
          cellphone: "Celular",
          phone: "Teléfono",
          status: "Estado",
          actions: "Acciones",
        }
      },
      modals: {
        delete_responsible: {
          header: "¡Importante!",
          message: "¿Realmente desea eliminar este responsable? Al hacer clic en el botón Sí, los cambios se realizarán de inmediato."
        },
        inactivate_company: {
          header: "¡Importante!",
          message: "¿Realmente desea inactivar esta empresa? Al hacer clic en el botón Sí, los cambios se realizarán de inmediato."
        },
        new_responsible: {
          header: "Nuevo Responsable",
          button_cancel: "Cancelar",
          button_save: "Guardar",
          button_back: "Volver"
        },
        edit_reponsible: {
          header: "Editar Responsable",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        }
      },
      button_yes: "Sí",
      button_no: "No",
      active: "activo",
      inactive: "inactivo",
      button_edit: "Editar",
      button_new_responsible: "Nuevo Responsable",
      general_information: "INFORMACIÓN GENERAL",
      managers: "Responsables",
      no_stores: "No hay tiendas registradas",
      no_stores_description: "Registre una nueva tienda haciendo clic en el botón de arriba",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Nombre de la Tienda",
          responsible: "Responsable",
          email: "E-mail",
          phone: "Teléfono",
          city: "Ciudad",
          state: "Estado",
          cellphone: "Celular",
        }
      },
      modals: {
        new_store: {
          header: "Nueva Tienda",
          button_cancel: "Cancelar",
          button_save: "Guardar",
          store_name_required: "Nombre de la tienda es obligatorio",
          city_required: "Ciudad es obligatorio",
          state_required: "Estado es obligatorio",
          email_invalid: "E-mail inválido",
        },
        edit_store: {
          header: "Editar Tienda",
        }
      },
      store_list: "LISTADO DE TIENDAS",
      button_filter: "Filtros",
      button_add_store: "Agregar Tienda",
      fetch_store_error: "Error al buscar tiendas",
      error_no_enterprise: "No se ha seleccionado ninguna empresa",
    },
    company_branches: {
      tables: {
        branches: {
          id: "Código",
          name: "Nombre",
          tax_id: "CNPJ",
          type: "Tipo de sucursal",
          city: "Ciudad",
          state: "Estado",
          responsible: "Responsable",
          store: "Tienda",
          status: "Estado",
          actions: "Acciones",
          branch_types: {
            physical_store: "Tienda Física",
            pickup_point: "Punto de Retiro",
            distribution_center: "Centro de Distribución",
            head_office: "Sede",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      modals: {
        new_branch: {
          header: "Nueva Sucursal",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        },
        edit_branch: {
          header: "Editar Sucursal",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        },
        filter: {
          show: '',
          form: {
            active: 'Activo',
            inactive: 'Inactivo',
            name: 'Nombre',
            tax_identifier: 'CIF',
            city: 'Ciudad',
            responsible: 'Responsable',
            uf: 'Provincia',
            store: 'Tienda'
          }
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ es obligatorio",
          branch_name: "Nombre de la Sucursal",
          branch_name_required: "Nombre de la Sucursal es obligatorio",
          postal_code: "Código Postal",
          postal_code_required: "Código Postal es obligatorio",
          address: "Dirección",
          address_required: "Dirección es obligatorio",
          address_complement: "Complemento",
          city: "Ciudad",
          city_required: "Ciudad es obligatorio",
          state: "Estado",
          state_required: "Estado es obligatorio",
          state_placeholder: "Seleccione un estado",
          state_registration: "Inscripción Estatal",
          responsable: "Responsable",
          store: "Tiendas",
          store_required: "Tienda es obligatorio",
          store_placeholder: "Seleccione una tienda",
          stores_no_result: "No hay tiendas registradas",
          branch_type: "Tipo de Sucursal",
          number: "Número",
          number_required: "Número es obligatorio",
          branch_type_placeholder: "Seleccione un tipo de sucursal",
          branch_type_required: "Tipo de Sucursal es obligatorio",
          branch_types: {
            physical_store: "Tienda Física",
            pickup_point: "Punto de Retiro",
            distribution_center: "Centro de Distribución",
            head_office: "Sede",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      error_search_branches: "Error al buscar sucursales",
      empty_branches: "No se encontraron sucursales",
      empty_branches_description: "Registre una nueva sucursal haciendo clic en el botón de arriba",
      filial_list: "LISTADO DE SUCURSALES",
      button_add_branch: "Agregar Sucursal",
      filters: "Filtros"
    },
    users: {
      tables: {
        users: {
          id: "Código",
          name: "Nombre",
          email: "E-mail",
          role: "Perfil",
          department: "Departamento",
          status: "Estado",
          actions: "Acciones",
        }
      },
      modals: {
        newUser: {
          newUser: "Nuevo Usuario",
          photo_size_recommend: "Recomendamos una imagen de 300x300 píxeles",
          alt_img_preview: "Vista previa de la imagen",
        },
        editUser: {
          editUser: "Editar Usuario",
          photo_size_recommend: "Recomendamos una imagen de 300x300 píxeles",
          alt_img_preview: "Vista previa de la imagen",
        }
      },
      forms: {
        new_user: {
          name: "Nombre",
          email: "E-mail",
          profile: "Perfil de Acceso",
          stores: "Tiendas",
          realesed_stores: "Tiendas Liberadas",
          department: "Departamento",
          name_required: "Nombre es obligatorio",
          email_required: "E-mail es obligatorio",
          email_invalid: "E-mail inválido",
          department_required: "Departamento es obligatorio",
          profile_required: "Perfil es obligatorio",
        }
      },
      no_users: "No se encontraron usuarios",
      no_users_description: "Registre un nuevo usuario haciendo clic en el botón de arriba",
      fetch_users_error: "Error al buscar usuarios",
      users_list: "LISTADO DE USUARIOS",
      search: "Buscar usuarios...",
      button_access_profiles: "Perfiles de Acceso",
      button_add_user: "Agregar Usuario",
    },
    users_profiles: {
      profile_management: "GESTIÓN DE PERFILES",
      button_duplicate_profile: "Duplicar Perfil",
      button_add_profile: "Agregar Perfil",
      available_options: "Opciones Disponibles",
      options_released: "Opciones Liberadas",
      clear_selection: "Quitar selección",
      profile_list: "LISTA DE PERFILES",
      list_of_functionalities: "LISTA DE FUNCIONALIDADES"
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Contraseña",
        no_account: "¿Aún no tienes una cuenta?",
        no_account_click_here: "Haz clic aquí",
        forgot_my_password: "Olvidé mi contraseña",
        log_in: "INICIAR SESIÓN"
      },
      forms: {
        login: {
          email_required: 'El e-mail es obligatorio.',
          password_required: 'La contraseña es obligatoria',
          email_invalid: 'Correo electrónico inválido.'
        },
        reset_password: {
          email_required: 'El e-mail es obligatorio.',
          email_invalid: 'Correo electrónico inválido.'
        }
      },
      reset_password: {
        title: 'Restablecer contraseña',
        confirm: 'RESTABLECER',
        return: 'para volver a la pantalla de inicio de sesión.',
        click_here: 'Haz clic aquí'
      },
      register: {
        email: "Correo electrónico",
        password: "Contraseña",
        sign_up: "REGISTRARSE",
        department: "Departamento",
        name: "Nombre",
        confirm_password: "Confirmar Contraseña"
      },
    },
    errors: {
      unexpected_error: {
        title: "¡ERROR INESPERADO!",
        description: "Se produjo un error inesperado en nuestro sistema, inténtelo nuevamente más tarde."
      }
    },
    products: {
      tabs: {
        general_data: "DATOS GENERALES",
        variations: "VARIACIONES",
        attributes: "ATRIBUTOS",
        precification: "PRECIFICACIÓN",
        stock: "STOCK",
        restrictions: "RESTRICCIONES",
      },
      products_list: {
        modals: {
          filter: {
            close_filter: "Cerrar Filtros",
            show: "Mostrar:",
            active: "Activos",
            inactive: "Inactivos",
            form: {
              sku_group: "Grupo SKU",
              product_type: "Tipo de Producto",
              category: "Categoría",
              brand: "Marca",
              price: "Precio",
              initial_amount: "Valor Inicial",
              final_amount: "Valor Final",
              stock: "Inventario",
              initial_quantity: "Cantidad Inicial",
              final_quantity: "Cantidad Final",
            },
            apply_filters: "Aplicar Filtros",
            clean_filters: "Limpiar Filtros",
          },
          variations: {
            product_variations: "Variaciones del Producto",
            sku_group: "Grupo SKU",
            title: "Título",
            table: {
              ref_code: "Código de Referencia",
              ean: "Código de Barras (EAN)",
              primary_characteristic: "Característica Primaria",
              secondary_characteristic: "Característica Secundaria",
              active: "Activo",
              inactive: "Inactivo",
            },
            close: "Cerrar",
            access_product: "Acceder al Producto",
          }
        },
        tables: {
          products: {
            sku_group: "Grupo SKU",
            image: "Imagen",
            title: "Título",
            product_type: "Tipo de Producto",
            kit_variation: "Kit con Variación",
            variation: "Con Variación",
            simple: "Simple",
            category: "Categoría",
            brand: "Marca",
            model: "Modelo",
            standard_price: "Precio Estándar",
            total_stock: "Inventario Total",
          }
        },
        title: "LISTADO DE PRODUCTOS",
        search: "Buscar productos...",
        filters: "Filtros",
        publications: "Publicaciones",
        add_product: "Agregar Producto",
        no_products: "No se encontraron productos",
        no_products_description: "Registre un nuevo producto haciendo clic en el botón de arriba",
      },
      details: {
        no_categories: "No hay categorías registradas",
        select_category: "Seleccionar una categoría",
        select_gender: "Seleccione un género",
        select_manufactorer: "Seleccione un fabricante",
        no_category: "No se encontró ninguna categoría",
        product_details: "DATOS GENERALES DEL PRODUCTO",
        history: "Historial",
        import_product: "Importar Producto",
        select_image: "Agregue imágenes en la pestaña Variaciones",
        sku_group: "Grupo SKU",
        product_status: "Estado del Producto",
        used_characteres: "Caracteres utilizados",
        forms: {
          product: {
            title: "Título",
            title_required: "Título es obligatorio",
            title_helper: "(Título enviado a los Marketplaces)",
            short_title: "Título Corto",
            category: "Categoría",
            brand: "Marca",
            model: "Modelo",
            nbm: "NBM/NCM",
            gender: "Género",
            volumes: "Volúmenes",
            manufacturer: "Fabricación",
            warranty_period: "Período de Garantía",
            months: "(Meses)",
            package_information: "INFORMACIÓN DE EMBALAJE",
            weight: "Peso",
            height: "Altura",
            width: "Ancho",
            depth: "Profundidad",
            cubage: "Cubaje",
            description: "Descripción",
          }
        },
        modals: {
          import_product: {
            import_product: "Importar Producto",
            import_button: "Importar",
          },
          history: {
            product_history: "Historial del Producto",
            close: "Cerrar",

          },
          commentaries: {
            product_commentaries: "Comentarios del Producto",
            digit_here: "Digite aquí...",
            send: "Enviar",
          }
        },
        editor: {
          bold: "Negrita",
          italic: "Itálico",
          underline: "Subrayado",
          help: "Se publicarán los valores máximos de caracteres permitidos por marketplace, por lo tanto, las descripciones con límite excedido se cortarán."
        }
      },
      variations: {
        no_variations: "No se encontraron variaciones",
        no_variations_descriptions: "Registre una nueva variación haciendo clic en el botón de arriba",
        product_variations: "VARIACIONES DEL PRODUCTO",
        characteristics_types: "Tipos de Características",
        add_variation: "Agregar Variación",
        main_variation: "Variación Principal",
        variation: "Variación",
        main_image: "Imagen Principal",
        photos_inserted: "Fotos insertadas",
        upload_photos: "Cargar Fotos",
        primary_characteristic: "Característica Primaria",
        secondary_characteristic: "Característica Secundaria",
        select: "Seleccionar...",
        ref_code: "Código de Referencia",
        bar_code: "Código de Barras",
        modals: {
          characteristics: {
            characteristics_type: "TIPO DE CARACTERÍSTICAS",
            add_characteristics_type: "Agregar Tipo de Características",
            name: "Nombre",
            value: "Valor",
          }
        }
      },
      precification: {
        sku_group: "Grupo de SKU",
        price_from: "Precio De",
        discount: "Descuento",
        standard_price: "Precio Estándar",
        sku: "SKU",
        reference_code: "Código de Referencia",
        primary_feature: "Característica Principal",
        secondary_feature: "Característica Secundaria",
        voltage: "Voltaje",
        barcode: "Código de Barras",
        standard_price_per_sku: "Precio Estándar por SKU",
        prices_practiced_in_marketplaces_title: 'Precios practicados en los marketplaces',
        prices_practiced_in_marketplaces: {
          channel: "Canal",
          price: "Precio",
          note: "Observación"
        }
      },
      attributes: {
        attributes_by_marketplace: "Atributos por Marketplace",
        category: "Categoría",
        attribute: "Atributo",
        value: "Valor",
        attribute_name_already_exists: "El nombre del atributo ya está siendo utilizado.",
        product_attributes: "Atributos del Producto",
        add_attribute: "Agregar Atributo",
        search: "Buscar"
      },
      stock: {
        available_stock_by_sku_and_branch: "STOCK DISPONIBLE POR SKU Y SUCURSAL",
        sku_group: "SKU Grupo",
        sku: "SKU",
        reference_code: "Código de Referencia",
        primary_characteristic: "Característica Principal",
        secondary_characteristic: "Característica Secundaria",
        gtin: "Código de Barras",
        available_total_sku_group: 'Inventario Total Disponible SKU Grupo',
        minimum_stock_sku_group: 'Inventario Mínimo SKU Grupo', 
        available_total: "Total Disponible",
        minimum_stock: "Stock Mínimo",
        restrictions_by_marketplace: "Restricciones por Marketplace",
        branch: {
          available_stock: "Stock Disponible",
          stock_type: "Tipo de Stock",
          safety_stock: "Stock de Seguridad",
          additional_lead_time: "Tiempo Adicional de Reposición",
          select: "Seleccione..."
        },
        modals: {
          
          help_stock: {
            help_stock: "Ayuda",
            subtitle: "TIPOS DE STOCK",
            stock_automatic_highlight: "Automático – Interacción vía API:",
            stock_automatic_description: "En esta opción, el “Stock Disponible” se actualizará vía API.",
            stock_automatic_observation: "El plazo de crossdocking “Tiempo Adicional” no se aplica a esta opción.",
            observation: "Observación:",
            observations: "Observaciones:",
            stock_fix_highlight: "Stock Fijo:",
            stock_fix_description: "En esta opción, el “Stock Disponible” se informará manualmente y no cambiará si hay ventas.",
            stock_fix_observation: "El valor informado en el campo “Plazo Adicional” de entrega se sumará al plazo de transporte.",
            reducing_stock: "Reduciendo el Stock:",
            reducing_stock_description: "En esta opción, el “Stock Disponible” se informará manualmente y disminuirá si hay ventas.",
            observation_1: "Si hay una cancelación de pedido pendiente, el stock no aumentará automáticamente.",
            observation_2: "El valor informado en el campo “Plazo Adicional” de entrega se sumará al plazo de transporte."
          }
        }
      },
      restrictions: {
        ref_code: "Código de Referencia",
        primary_characteristic: "Característica Primaria",
        secondary_characteristic: "Característica Secundaria",
        barcode: "Código de Barras",
        marketplace_restrictions: "Restricciones de Ventas por Marketplace"
      }
    }
  }
}

const i18n = createI18n({
  locale: 'pt_BR',
  fallbackLocale: 'EN',
  messages,
});

export default i18n;