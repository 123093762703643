import useAuth from '@/composables/useAuth';
import router from '@/router';
import { useAuthStore } from '@/stores/authStore';
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.linkhub.com.br/api/v1/'
});

api.interceptors.request.use((request) => {
  const { getToken } = useAuthStore();

  const token = getToken();

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      const { message } = error.response.data;

      if (["Token não encontrado no sistema", "Token inválido", "Token expirado"].includes(message)) {
        const { logout } = useAuth();
        logout(false);
        router.push('/login');
      }
    }
    return Promise.reject(error);
  }
);

export default api;